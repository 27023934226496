.app__calculator-page {
  min-height: calc(100vh - 200px);
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.welcome-window {
  width: 100%;
  /* background-color: aqua; */
  margin-top: 10%;
  border-radius: var(--app_border_radius);
  padding: 15px;
  background: rgba(184, 233, 134, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.welcome-window h1 {
  margin-bottom: 25px;
  color: #333;
  font-size: 26px;
  font-weight: 500;
}

/*Progress bar*/
.app__calculator-page .progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  margin-bottom: 20px;
  border-radius: var(--app_border_radius);
}
.app__calculator-page .progress-bar .progress {
  height: 10px;
  background-color: #4caf50;
  transition: all 0.3s ease-in-out;
  border-radius: var(--app_border_radius);
}

/*Progress bar end*/

/*Quiz styling start*/
.app__quiz-window {
  max-width: 600px;
  width: 100%;
  border-radius: var(--app_border_radius);
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-left: auto;
  margin-right: auto;
  background: rgba(184, 233, 134, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.quiz-row {
  /* height: 50%; */
  display: flex;
  flex-direction: column;
}

.quiz-row h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.quiz-row .quiz-comment {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-left: 10px;
}

.app__quiz-window .back-btn-holder {
  margin-bottom: 20px;
}

.app__quiz-window .back-btn-holder svg {
  border-radius: var(--app_border_radius);
  cursor: pointer;
  color: #333;
  font-size: 35px;
  padding: 0;
}

.quiz-questions {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-between;
}

/*measurement input*/
.measurement-container {
  position: relative;
}

.measurement-input {
  margin-bottom: 10px;
  width: 100%;
  border-radius: var(--app_border_radius);
  padding: 5px 10px;
  height: 40px;
  font-size: 16px;
  background-color: #fff;
  border: none;
  transition: box-shadow 0.3s, background-color 0.3s;
  box-sizing: border-box;
  padding-right: 40px;
}
.measurement-input::-webkit-outer-spin-button,
.measurement-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Safari and Chrome */
}

.measurement-input:focus {
  box-shadow: inset 0 0 0 2px #37b221, 0 0 8px #37b221;
  background-color: #ebf7e9;
  outline: none;
}

.measurement-input {
  -moz-appearance: textfield; /* Firefox */
}

.unit {
  position: absolute;
  right: 20px;
  top: 40%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #666;
}

/*Gender window*/
.gender-label {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: var(--app_border_radius);
  transition: box-shadow 0.3s, background-color 0.3s;
}

.gender-input {
  font-size: 18px;
}

/*Calculator btn styling*/
.calculator_button,
.app__quiz-window .answer-btn {
  background-color: #4caf50;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 6px 12px;
  border-radius: var(--app_border_radius);
  max-width: 250px;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: none;
  cursor: pointer;
}

.calculator_button:hover,
.app__quiz-window .answer-btn:hover {
  background-color: #333;
}

.app__quiz-window .answer-btn {
  background-color: #4caf50;
  margin-top: 25px;
  text-align: center;
  margin: auto;
  max-width: 300px !important;
}

/*Responsive settings*/
@media (min-width: 1024px) {
  .welcome-window {
    width: 50%;
    background-color: transparent;
    box-shadow: none;
    margin-top: 120px;
    backdrop-filter: initial;
    border: none;
  }
  .welcome-window h1 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .app__calculator-page {
    min-height: calc(100vh - 100px);
  }
}

@media (max-width: 520px) {
  .calculator_button,
  .app__quiz-window .answer-btn {
    max-width: 100%;
  }
}
