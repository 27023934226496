.tr-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  align-items: center;
}

.app-w-bg {
  background-image: url("../media/app-bg.jpg");

  background-color: #fff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: right;
}

.tr-app .content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tr-app .content .app__page {
  /* max-width: 1150px; */
  width: 100%;
  min-height: calc(100vh - 150px);
}

.tr-app .quiz-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: transparent;
  max-width: 1150px;
  width: 100%;
}

.header-logo {
  padding: 10px;
}

.header-logo img {
  max-height: 75px;
}

/* FOOTER */
.app__footer {
  background-color: #ebf7e9;
  width: 100%;
  min-height: 250px;
  padding: 50px 0px;
}

.app__footer .footer_wrapper {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  /* flex-wrap: wrap; */
}

.app__footer .footer_col {
  width: 33%;
  display: flex;
}

.app__footer .footer_infos {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.app__footer .footer_infos strong,
span {
  font-size: 18px;
  color: #333;
}

.app__footer .footer_infos span,
.app__footer .footer_infos a {
  cursor: pointer;
  text-decoration: none;
}

.app__footer .footer_infos span:hover {
  color: #4caf50;
}

.d-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.app__footer .footer_col img {
  max-width: 200px;
}

.footer_icons a {
  color: #4caf50;
  font-size: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.footer_icons .footer_icon:hover {
  color: #333;
  cursor: pointer;
}

@media (max-width: 650px) {
  .app__footer .footer_col {
    width: 100%;
  }
  .app__footer .footer_wrapper {
    flex-direction: column;
    gap: 20px;

    /* flex-wrap: wrap; */
  }

  .d-center {
    align-items: flex-start;
  }

  .d-end {
    align-items: flex-start;
  }
}

@media (max-width: 1023px) {
  .app-w-bg {
    background-image: url("../media/app-bg-horizontal-2.jpg");
    background-position: 0 -150px !important;
    background-size: cover;
    background-attachment: scroll;
  }
}
