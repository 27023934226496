:root {
  --app__bg: #f0e4d4;
  --app_primary_color: #fff;
  --app_secondary_color: #fff;
  --app_border_radius: 18px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(180, 35, 35, 0);
  /* background-color: #fff; */
}

.app__shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
