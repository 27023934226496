.contact_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(76, 175, 80, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact_form {
  background-color: #fff;
  padding: 10px;
  max-width: 560px;
  width: 100%;
  border-radius: var(--app_border_radius);
}

.contact_form-input {
  margin-bottom: 10px;
  width: 100%;
  border-radius: var(--app_border_radius);
  padding: 5px 10px;
  height: 40px;
  font-size: 16px;
  background-color: #f1f1f1;
  border: none;
  transition: box-shadow 0.3s, background-color 0.3s;
  box-sizing: border-box;
  padding-right: 40px;
}
.contact_form-input::-webkit-outer-spin-button,
.contact_form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Safari and Chrome */
}

.contact_form-input:focus {
  box-shadow: inset 0 0 0 2px #37b221, 0 0 8px #37b221;
  background-color: #ebf7e9;
  outline: none;
}

.contact_form-input {
  -moz-appearance: textfield; /* Firefox */
}

.contact_form-textarea {
  margin-bottom: 10px;
  width: 100%;
  border-radius: var(--app_border_radius);
  padding: 10px 10px;
  height: 100px;
  font-size: 16px;
  background-color: #f1f1f1;
  border: none;
  transition: box-shadow 0.3s, background-color 0.3s;
  box-sizing: border-box;
  padding-right: 40px;
  resize: none;
}
.contact_form-textarea::-webkit-outer-spin-button,
.contact_form-textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Safari and Chrome */
}

.contact_form-textarea:focus {
  box-shadow: inset 0 0 0 2px #37b221, 0 0 8px #37b221;
  background-color: #ebf7e9;
  outline: none;
}

.contact_form-textarea {
  -moz-appearance: textfield; /* Firefox */
}

.contact_form_submit_btn {
  background-color: #4caf50;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 6px 12px;
  border-radius: var(--app_border_radius);
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: none;
  cursor: pointer;
}

.contact_form_close_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 25px;
  color: #333;
}

.contact_form h2 {
  margin-bottom: 20px;
  color: #333;
}

.error_box {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border-radius: var(--app_border_radius);
  background-color: #f8d7da;
  /* color: #155724; */
  color: #721c24;
}

.success_box {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border-radius: var(--app_border_radius);
  background-color: #d4edda;
  color: #155724;
}

.success_box button {
  padding: 6px 12px;
  background-color: #155724;
  color: #fff;
  border: none;
  border-radius: var(--app_border_radius);
  margin-top: 10px;
  cursor: pointer;
  font-size: 16px;
}
